//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Search from "@/components/Search"
export default {
    name: "Cart",
    components: { Search },
    data() {
        return {
            config: [
                { label: "全選", icon: true, class: "w3" },
                { label: "商品信息", class: "w30" },
                { label: "單價", class: "w15" },
                { label: "數量", class: "w15" },
                { label: "金額", class: "w15" },
                { label: "操作", class: "w15" }
            ],
            checkedAll: true,
            allMoney: 0,
            allNUmber: 0,
            dataList: [],
            invalidList: [],
            response: '',
        };
    },
    created() {
        this.getDataList()
    },
    methods: {
        pay(){
            this.$router.push({name: 'orderConfirm'})
        },
        async getDataList() {
            const res = await this.$http.get('/cart')
            if (res.code !== 0) {
                if (res.data.code === 401) {
                    window.location.href='/#/login'
                    return false
                }
                return this.$message.error(res.message);
            }
            let data = res.data
            let dataList = []
            let invalidList = []
            data.items.data.list.forEach(item => {
                if (item.is_invalid) {
                    invalidList.push(item)
                } else {
                    dataList.push(item)
                }
            })
            this.dataList = dataList
            this.invalidList = invalidList
            this.response = data
        },
        // 更新商品信息
        async editCartGoods(goods) {
            const res = await this.$http.post('/cart/edit', {
                goods_id: goods.goods_id,
                goods_sku_key: goods.goods_sku_key,
                goods_number: goods.goods_number,
                is_checked: goods.is_checked,
                is_direct: 0,
            })
            if (res.code !== 0) {
                return this.$message.error(res.message)
            }
            this.getDataList()
        },

        numberChange(goods,type='reduce'){
            this.editCartGoods(goods,type)
        },

        checked(index) {
			this.dataList[index].is_checked =!this.dataList[index].is_checked
            this.editCartGoods(this.dataList[index])
        },

        //全選
        async toggleAllCheckButton() {
            const res = await this.$http.post('/cart/changeSelectStatus', {
                status: this.response.submit.data.field.is_all_checked ? 0 : 1
            })
            if (res.code !== 0) return this.$message.error(res.message)
            this.getDataList()
        },


        async deleteItem(goods) {
            const res = await this.$http.post('/cart/delete', {
				cart_id: goods.cart_id
			})
            if (res.code !== 0) {
                return this.$message.error(res.message)
            }
            this.getDataList()
        },

        isCheckedAll() {
            this.checkedAll = this.cartList.every(item=>item.checked == true)
            this.allMoney = 0
            this.allNUmber = this.cartList.reduce((val,item,index)=>{
                item.checked == true && (this.allMoney = this.allMoney + (item.shop_price * item.goods_number))
                item.checked == true && (val += item.goods_number)
                return val
            },0)
        },
    }
};
